
import '#/assets/fonts/open-sans/open-sans.css';
import { defineComponent} from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import store from '@/store';
import { APP_FETCH_THEME } from '@/store/actions.type';
import { COMMUNITY_FETCH_INFO, COMMUNITY_FETCH_STATUS } from '@/store/community/info.module';
import Modal from '#/components/Modal';
export default defineComponent({
  name: 'App',
  computed:{
    ...mapGetters([
      'enlargedPic',
      'theme'
    ]),
    themeProps() {
      return {
        '--primary-color': this.theme.mainColor,
        '--primary-color-text': this.theme.mainColorText || this.theme.mainColor,
        '--accent-color': this.theme.buttonColor,
        '--text-color': this.theme.textColor,
        '--gradient1-color':this.theme.backgroundGradientColor1,
        '--gradient2-color':this.theme.backgroundGradientColor2,
      };
    },
    getUrlLegal(){
      return window.location.origin + "/landings/Legal"
    }
  },
  components: {
    Modal,
  },
  methods:{
    ...mapMutations({
      enlargePic: 'enlargePic',
    })
  },
  async created() {
    await store.dispatch(APP_FETCH_THEME, {
      origin: store.getters.origin,
    });

    // Customize document's title and favicon.
    if (this.$route.name != 'mainPage') {
      document.title = store.getters.theme.title + ' - WAT Lab';
      let favicon = <HTMLLinkElement>document.getElementById('favicon')
      if(favicon) favicon.href = store.getters.theme.favicon;
    }

    if ((this.theme.textColor == undefined || this.theme.textColor == null) && 
        (this.theme.mainColor == undefined && this.theme.mainColor == null)) {
      this.$router.push({ name: 'profile' });
    }
  },
});
